import Header from '@/components/header';
import { ReactNode, useEffect, useState } from 'react';
import { sdk } from '@/api.ts';
import { IOrganization } from '@/components/chat/types.ts';
import { Card } from '@/components/ui/card.tsx';
import { BiChevronRight } from 'react-icons/bi';
import faceYellow from '@/assets/images/logo/face-yellow.svg';
import { useAuth } from '@/providers/auth.tsx';
import { Link, Outlet, useLocation } from 'react-router-dom';
import Sidebar from '@/routes/dashboard/sidebar.tsx';

const ControlPanelCard = ({
  children,
  linkTo = '#',
  title,
}: {
  children: ReactNode;
  linkTo?: string;
  title: string;
}) => {
  return (
    <Card className="w-[200px]">
      <Link to={linkTo}>
        <h4 className="text-sm font-semibold flex items-center underline">
          {title} <BiChevronRight size={20} />
        </h4>
      </Link>
      <div className="text-xs mt-4">
        <span className="font-bold">Quick Links</span>
        <div className="[&>*]:underline flex flex-col">{children}</div>
      </div>
    </Card>
  );
};

const Dashboard = () => {
  const { user } = useAuth();
  const location = useLocation();
  const isBaseRoute = location.pathname === '/dashboard';
  const [organization, setOrganization] = useState<IOrganization | null>(null);
  useEffect(() => {
    sdk
      .getOrganization(user.organization_id)
      .then((response) => setOrganization(response.data))
      .catch((error) => console.error(error));
  }, []);

  if (!organization) {
    return <p>Loading...</p>;
  }

  return (
    <div className="flex flex-col h-screen">
      <Header />

      <div className="flex flex-1">
        <Sidebar organization={organization} />
        <main className="flex-1 p-8 bg-app-background">
          {isBaseRoute && (
            <div className="max-w-screen-md">
              <div className="flex text-sm">
                <div className="p-4 border-0 border-r border-dark flex">
                  <div className="px-4 pt-6 max-w-[70px]">
                    <img src={faceYellow} alt="" />
                  </div>
                  <div>
                    <h3 className="font-semibold mb-2 underline">To Do's</h3>
                    <p className="text-xs">
                      You have no action items needing your attention
                    </p>
                  </div>
                </div>
                <div className="p-4">
                  <h3 className="font-semibold mb-4 underline">Engagement</h3>
                  <p>
                    <span className="font-bold text-lg">12,000</span> chats this
                    week
                  </p>
                  <p>
                    <span className="font-bold text-lg">120</span> handoffs to
                    advisors
                  </p>
                  <p>
                    <span className="font-bold text-lg">60</span> new accounts
                  </p>
                </div>
                <div className="p-4 pt-14">
                  <p>
                    <span className="font-bold text-lg">68%</span> meaningful
                    metric
                  </p>
                  <p>
                    <span className="font-bold text-lg">8.6</span> customer
                    score
                  </p>
                  <p>
                    <span className="font-bold text-lg">600</span> new users
                  </p>
                </div>
              </div>
              <h1 className="text-2xl font-semibold">Control Panel</h1>
              <div className="flex flex-wrap gap-4 mt-4">
                <ControlPanelCard
                  title="Participants"
                  linkTo="/dashboard/participants"
                >
                  <Link to="/dashboard/participants">Invite Participants</Link>
                  <Link to="/dashboard/participants">
                    Manage participant users
                  </Link>
                  <Link className="mt-4" to="/dashboard/participants">
                    See all
                  </Link>
                </ControlPanelCard>
                <ControlPanelCard title="Company Info">
                  <Link to="/dashboard/organization">
                    Tell us about your organization
                  </Link>
                  <Link className="mt-4" to="/dashboard/organization">
                    See all
                  </Link>
                </ControlPanelCard>
                <ControlPanelCard title="Manage Admins">
                  <Link to="/dashboard/administrators">
                    Set up new admins for organization
                  </Link>
                  <Link to="/dashboard/administrators">Manage admin users</Link>
                  <Link className="mt-4" to="/dashboard/administrators">
                    See all
                  </Link>
                </ControlPanelCard>
                <ControlPanelCard title="Reporting">
                  <Link to="#">Create new report</Link>
                  <Link to="#">See usage metrics</Link>
                  <Link to="#">See service reports</Link>
                  <Link className="mt-4" to="#">
                    See all
                  </Link>
                </ControlPanelCard>
              </div>
            </div>
          )}
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
