import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { sdk } from '@/api.ts';
import { IInvite } from '@/components/chat/types.ts';
import InviteAccept from '@/routes/invite/invite-accept.tsx';
import TermsConditions from '@/routes/invite/terms-conditions.tsx';

// The Invite screen manages the full invite flow from acceptance forward.
// It manages the user state from the email invite to the terms and conditions
// to the dashboard.
// This is why we manage the user state here (via the invite data + any modified
// form data) and control the active views based on the query params provided.
const Invite = () => {
  const navigate = useNavigate();
  const [invalid, setInvalid] = useState(false);
  const [invite, setInvite] = useState<IInvite | null>(null);
  // This (userData) will be the user data that the user has confirmed to be
  // correct via the form that was pre-filled from the invite data.
  const [userData, setUserData] = useState(null);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const id: string | null = searchParams.get('id');

  // Check to see if the id is present and valid in the searchParams
  useEffect(() => {
    sdk
      .getInvite(id!)
      .then((response) => {
        setInvite(response.data);
      })
      .catch(() => {
        setInvalid(true);
      });
  }, []);

  const handleUserDataConfirm = (data: any) => {
    setUserData(data);
  };

  const handleAcceptTerms = () => {
    sdk
      .acceptInvite(userData)
      .then(() => {
        setLoading(false);
        navigate('/login?invite_accepted=true');
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        // @TODO handle error?
      });
  };

  return (
    <div className="bg-app-background min-h-screen flex justify-center items-center">
      {!userData ? (
        <InviteAccept
          invalid={invalid}
          invite={invite}
          onConfirmData={handleUserDataConfirm}
        />
      ) : (
        <TermsConditions onSubmit={handleAcceptTerms} loading={loading} />
      )}
    </div>
  );
};

export default Invite;
